import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Catalog from "../static/catalog.pdf"
import Toptitle from "../components/toptitle"

const Faq = () => (
  <Layout>
    <SEO title="よくある質問" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle className="text-right font-mincho text-8xl text-primarySupport md:pt-24">
          FAQ
        </Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">よくある質問</h1>
        </div>
        <div className="mt-8 w-full lg:w-2/3">
          <div className="w-full font-gothic">
            <div className="bg-primary  py-4 px-8 text-white">
              <p>Q. 不燃認定番号を教えて下さい。</p>
            </div>
            <div className="border border-primary py-4 px-8">
              <p>A. 国土交通大臣不燃認定 NM-8578(12mm以上) NM-2086(12mm未満)</p>
            </div>
          </div>
          <div className="mt-8 w-full font-gothic">
            <div className="bg-primary  py-4 px-8 text-white">
              <p>Q. 1本や1枚からの発注は可能ですか？</p>
            </div>
            <div className="border border-primary py-4 px-8">
              <p>A. はい、可能です。ぜひお問い合わせください。</p>
            </div>
          </div>
          <div className="mt-8 w-full font-gothic">
            <div className="bg-primary  py-4 px-8 text-white">
              <p>Q. 特注対応は可能ですか？</p>
            </div>
            <div className="border border-primary py-4 px-8">
              <p>A. はい、可能です。ぜひお問い合わせください。</p>
            </div>
          </div>
          <div className="mt-8 w-full font-gothic">
            <div className="bg-primary  py-4 px-8 text-white">
              <p>Q. 既製品はありますか？</p>
            </div>
            <div className="border border-primary py-4 px-8">
              <p>
                A. はい、ございます。カタログは
                <a
                  href={`${Catalog}`}
                  className="underline underline-offset-2"
                  download="catalog.pdf"
                >
                  こちら
                </a>
                からダウンロードできます。
              </p>
            </div>
          </div>
          <div className="mt-8 w-full font-gothic">
            <div className="bg-primary  py-4 px-8 text-white">
              <p>Q. 納期の目安を教えて下さい。</p>
            </div>
            <div className="border border-primary py-4 px-8">
              <p>
                A.
                受注生産のため、数量・形状によって異なります。お問い合わせください。
              </p>
            </div>
          </div>
          <div className="mt-8 w-full font-gothic">
            <div className="bg-primary  py-4 px-8 text-white">
              <p>Q. 事前にサンプルの作成は依頼できますか？</p>
            </div>
            <div className="border border-primary py-4 px-8">
              <p>
                A. 可能ですが、数量・形状によって有償となる場合がございます。
              </p>
            </div>
          </div>
          <div className="mt-8 w-full font-gothic">
            <div className="bg-primary  py-4 px-8 text-white">
              <p>Q. 施工について詳しく知りたいのですが。</p>
            </div>
            <div className="border border-primary py-4 px-8">
              <p>
                A.
                <Link to="/construct" className="underline underline-offset-1">
                  こちら
                </Link>
                のページにまとめております。不明点はお問い合わせください。
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Faq
